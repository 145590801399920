import { defineStore } from 'pinia'
import Api from "@/services/Api";
import numeral from "numeral";
import { useDiaryStore } from '@/stores/diary'

export const useMainSearchStore = defineStore({
  id: 'main_search',
  state: () => ({
    search_val: '',
    search_results: [],
    search_tab: 1, // 1:product, 2: customer
    search_customer_val: '',
    customers_type_list: [
      {id: 1, name: 'Person privat'},
      {id: 2, name: 'Biznes i vogel'},
      {id: 3, name: 'Biznes i madh'},
    ],
    selected_customer_type: "",
    customer_search_results: [],
    selected_customer: {},
    show_customers_search_results: false,
    selected_pos_id: '',
    selected_pos_name: '',
    selected_pos: {},
    products_cart: []

  }),
  getters: {

  }, 
  actions: {
    async search_product() {
      await Api(true).post("/mainsearch/product/search", { search_val: this.search_val})
      .then(res => {
        this.search_results = [...res.data]
      })
    },
    amount_format(value) {
      // Use numeral to format the number
      return numeral(value).format('0');
    },
    toggle_tab(val) {
      this.search_tab = val
    },
    async search_product_customer() {

      await Api(true).post("/mainsearch/customer/search", {
        search_val: this.search_customer_val
      }).then(res => {
        if (res.data.length > 0) {
          this.customer_search_results = res.data
          this.show_customers_search_results = true
        }
        // TODO: else, no search results...
        
      })
    },
    async select_result_customer(customer) {
      this.selected_customer = {}
      this.selected_customer = customer
      this.show_customers_search_results = false

      await Api(true).post("/mainsearch/customer/search/select", {
        customer
      }).then(res => {
        if(res.data.diary_list && res.data.diary_list.length > 0) {
          this.selected_customer.diary_list = []
          this.selected_customer.diary_list = res.data.diary_list
        }
      })
    },
    formatdob(dateString) {
      // Split the date string into parts
      const parts = dateString.split('-');
      // Format the date using numeral.js
      return `${parseInt(parts[2])}/${parseInt(parts[1])}/${parts[0]}`;
    },
    formatDate(dateString) {
      const [datePart] = dateString.split(' ');
      const [year, month, day] = datePart.split('-');
      return `${parseInt(day)}.${parseInt(month)}.${year}`;
    },
    add_to_cart(is_same_pos, warehouse_type, warehouse = null, product) {
      var a = {}
      a.warehouse_type = warehouse_type
      a.warehouse = {}
      a.is_same_pos = is_same_pos
      if(is_same_pos == 1){
        a.pos_id = this.selected_pos_id
        a.pos_name = this.selected_pos_name
        a.product = { ...product.product }
        a.warehouse.amount =
          warehouse_type == 1 ? parseInt(product.product.free_stock_amount) : 
          warehouse_type == 2 ? parseInt(product.product.reservation_stock_amount) : 
          warehouse_type == 3 ? parseInt(product.product.showcase_amount) : 
          warehouse_type == 4 ? parseInt(product.product.broken_stock_amount) : 0
        }
        else {
        a.warehouse = warehouse
        a.product = product.product
      }

      a.product.requested_amount = 1;


      this.products_cart.push({ ...a })

    },
    remove_cart_item(item) {
      this.products_cart = this.products_cart.filter(i => i != item)
    },
    async add_products(diary) {
      var confirm_text = ""
      if(diary) confirm_text = "Doni te shtoni produktet e zgjedhura ne ditar?"
      else confirm_text = "Doni te krijoni nje ditar te ri me produktet e zjedhura?"
      if (confirm(confirm_text)) {
        await Api(true).post("/mainsearch/add/products", {
          products: this.products_cart,
          diary, 
          entity_id: !diary ? null : diary.entity_id, 
          pos: !diary ? this.selected_pos_id :  diary.pos_id
        })
        .then(res => {
          if (res.data.length > 0 && diary?.guid) {
            // Get diary
            useDiaryStore().getDiary(diary.guid)
            // Close modal
            useDiaryStore().toggleAddProduct = false
            // Empty selected
            this.products_cart = []
          }
          else if(res.data.length > 0 && !diary) {
            window.location.href = "/diary/get/" + res.data[0].document
          }
        })
      }
    }

  }
})
